import React, { useState } from "react";
import { SelectedItem } from "@/app/(slideshow)/build/store/build-store";
import Image from "next/image";
import PlayTechLogo from "@/public/playtech-colour.png";
import {
  ProductAdditionalTypes,
  ProductImageTypes,
} from "@/types/products-types";
import { CartItemTypes } from "@/types/additional-types";
import { Json } from "@/lib/database.types";
import { Separator } from "@/components/ui/separator";

interface CommonProps {
  build: SelectedItem[] | null;
  productArr: {
    additional_data: Json;
    id: string;
    product_images: ProductImageTypes;
  }[];
  componentRef: React.MutableRefObject<null>;
}

interface OrderPDFProps extends CommonProps {
  pdfType: "order";
  orderData: {
    user_email: string;
    order_id: string;
    created_at: string;
  };
}

interface CartPDFProps extends CommonProps {
  pdfType: "cart";
  cartItems: CartItemTypes;
}

type PDFGENTYPES = OrderPDFProps | CartPDFProps;

const PDFGenerator = (props: PDFGENTYPES) => {
  const { build, productArr, componentRef, pdfType } = props;
  const [selectedPdfType] = useState<"cart" | "order">(pdfType);

  const getTotalPriceAndCount = (
    selectedHardware: SelectedItem[],
  ): { totalPrice: number; totalCount: number } => {
    let totalPrice = 0;
    let totalCount = 0;

    selectedHardware.forEach((item: SelectedItem) => {
      const amount = item.amount || 1;
      if (item.stock) {
        totalPrice += item.stock.price * amount;
      }
      totalCount += amount;
    });

    return { totalPrice, totalCount };
  };

  const { totalPrice, totalCount } = getTotalPriceAndCount(build || []);

  return (
    <div className="fixed -z-50 w-full font-mono text-xs opacity-0">
      <div
        ref={componentRef}
        className="flex h-full w-full flex-col items-center justify-start bg-white px-2 py-2 uppercase text-black"
      >
        <div className="justify-star flex h-full w-full flex-col items-start shadow-sm">
          <div
            id="header"
            className="flex flex-row items-center justify-start gap-6 pb-2"
          >
            <Image
              height={48}
              width={48}
              src={PlayTechLogo}
              alt="playtech-logo"
            />
            <div className="flex h-full flex-col items-start justify-start leading-snug">
              <span className="font-medium">
                PLAY TECH COMPUTER SOLUTIONS (PVT) LTD
              </span>
              <span className="text-sm">NO 39/A , MALWALA , RATNAPURA</span>
              <span className="text-sm opacity-70">076 996 5262</span>
            </div>
          </div>
          <Separator />

          {selectedPdfType === "order" && "orderData" in props && (
            <div
              id="details"
              className="flex flex-col items-start justify-start gap-0 pb-2 pt-3 text-xs font-light opacity-80"
            >
              <span className="text-base font-semibold text-primary">
                QUOTATION
              </span>
              <span>Email: {props.orderData.user_email}</span>
              <span>Order Id: {props.orderData.order_id}</span>
              <span>
                Date:
                {props.orderData.created_at}
              </span>
            </div>
          )}

          <div className="mt-4 w-full">
            <div className="mb-2 grid grid-cols-[56px,2fr,0.8fr,0.6fr,1fr] grid-rows-1 place-items-start border-b border-border bg-slate-100 px-2">
              <div className="w-full whitespace-nowrap rounded-md py-2 text-start text-sm font-semibold">
                Img
              </div>
              <div className="ml-2 whitespace-nowrap rounded-md py-2 text-sm font-semibold">
                Name
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-center text-sm font-semibold">
                Warranty
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-center text-sm font-semibold">
                Qty
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-right text-sm font-semibold">
                Price
              </div>
            </div>
            {selectedPdfType === "cart" &&
              "cartItems" in props &&
              build?.map((hardware, index) => {
                const product = productArr?.find((p) => p.id === hardware.id);
                const cartItem = props.cartItems.find(
                  (item) => item.id === hardware.id,
                );
                const amount = cartItem?.amount || 1;
                return (
                  <div
                    className="mb-2 grid grid-cols-[56px,2fr,0.8fr,0.6fr,1fr] grid-rows-1 place-items-start border-b border-border px-2 text-xs"
                    key={hardware?.id + index}
                  >
                    {product ? (
                      product.product_images[0].img ? (
                        <Image
                          src={product.product_images[0].img}
                          width={48}
                          height={48}
                          alt={hardware?.id}
                          className="aspect-square size-full object-contain"
                        />
                      ) : (
                        <div className="aspect-square size-full rounded bg-slate-500 opacity-40" />
                      )
                    ) : (
                      <div className="aspect-square size-full rounded bg-slate-500 opacity-40" />
                    )}

                    <div className="ml-2 rounded-md bg-white/80 p-1">
                      {hardware?.name}
                    </div>
                    <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center uppercase">
                      {(
                        product?.additional_data as ProductAdditionalTypes
                      ).find((v) => v.type === "warranty")?.value ?? "N/A"}
                    </div>
                    <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center">
                      {amount}
                    </div>
                    {hardware?.stock && (
                      <div className="ml-0 flex w-full flex-row items-center justify-end gap-3 rounded-md bg-white/80 p-1 text-right text-sm">
                        {hardware.stock.price * amount}
                      </div>
                    )}
                  </div>
                );
              })}
            {selectedPdfType === "order" &&
              build?.map((hardware, index) => {
                const product = productArr?.find((p) => p.id === hardware.id);
                return (
                  <div
                    className="mb-2 grid grid-cols-[56px,2fr,0.8fr,0.6fr,1fr] grid-rows-1 place-items-start border-b px-2 pb-1 text-xs"
                    key={hardware?.id + index}
                  >
                    {product ? (
                      (product.product_images as ProductImageTypes)[0].img ? (
                        <Image
                          src={
                            (product.product_images as ProductImageTypes)[0].img
                          }
                          width={48}
                          height={48}
                          alt={hardware?.id}
                          className="aspect-square size-full object-contain"
                        />
                      ) : (
                        <div className="aspect-square size-full rounded bg-slate-500 opacity-40" />
                      )
                    ) : (
                      <div className="aspect-square size-full rounded bg-slate-500 opacity-40" />
                    )}

                    <div className="ml-2 rounded-md bg-white/80 p-1">
                      {hardware?.name}
                    </div>
                    <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center uppercase">
                      {(
                        productArr?.find((p) => p.id === hardware.id)
                          ?.additional_data as ProductAdditionalTypes
                      )?.find((v) => v.type === "warranty")?.value ?? "N/A"}
                    </div>
                    <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center">
                      {hardware.amount || 1}
                    </div>
                    {hardware?.stock && (
                      <div className="ml-0 flex w-full flex-row items-center justify-end gap-3 rounded-md bg-white/80 p-1 text-right text-sm">
                        {hardware.stock.price * (hardware.amount || 1)}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="mt-8">
            <p className="text-xs font-bold">Total Price: {totalPrice}LKR</p>
            <p className="text-xs font-bold">Total Item Count: {totalCount}</p>

            <div className="flex h-auto w-full flex-col items-center justify-center px-4 pt-4">
              <p className="w-auto text-pretty pt-2 text-center text-xs font-bold opacity-60">
                Please note that this is only a Digitally Generated Quotation
                and not an Invoice and these prices are valid for 7 days only.
              </p>
              <p className="pt-2 text-center text-xs font-semibold text-primary">
                Thank You!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
